import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  notification,
} from 'tera-dls';
import SalesExpensesApi from '../../api';
import { SALES_EXPENSES_URL } from '../../url';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { usePermission } from '_common/hooks/usePermission';
import { SALES_EXPENSES_PERMISSION_KEY } from '_common/constants/permission';
import HoverQuickView from '_common/component/HoverQuickView';

function SalesExpensesTable(props: ITeraTableProps) {
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => SalesExpensesApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-sales-expenses-list']);
          queryClient.invalidateQueries(['get-summary-sales-expenses-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa chi phí bán hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa chi phí bán hàng</p>
          <p>
            của đầu tư <b>{record?.invest_id?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(SALES_EXPENSES_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(`${SALES_EXPENSES_URL.detail.path}/${record?.id}`),
      });
    if (hasPage(SALES_EXPENSES_PERMISSION_KEY.UPDATE))
      items.push({
        key: '2',
        label: 'Sửa',
        onClick: () =>
          record?.id &&
          navigate(`${SALES_EXPENSES_URL.update.path}/${record?.id}`),
      });
    if (hasPage(SALES_EXPENSES_PERMISSION_KEY.DELETE))
      items.push({
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_id',
      width: 200,
      render: (investor_id, record) => (
        <HoverQuickView
          avatarUrl={record?.invest_id?.investor?.file_upload}
          name={record?.invest_id?.investor?.investor_name || 'Hệ thống'}
          code={record?.invest_id?.investor?.code}
          email={record?.invest_id?.investor?.email}
          phone={record?.invest_id?.investor?.phone}
        >
          <p className="line-clamp-2 break-word">
            {record?.invest_id?.investor?.investor_name || 'Hệ thống'}
          </p>
        </HoverQuickView>
      ),
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_id',
      width: 200,
      render: (invest_id) => (
        <p className="line-clamp-2 break-word">{invest_id?.name}</p>
      ),
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (_, record) => INVESTMENT_CATEGORY[record?.invest_id?.type],
    },
    {
      title: 'Tổng chi phí bán hàng',
      dataIndex: 'sale_cost_items_total_price',
      width: 150,
      render: (sale_cost_items_total_price) =>
        formatCurrency(sale_cost_items_total_price?.total_price),
    },
    {
      title: 'Thiết bị',
      dataIndex: 'device',
      width: 200,
      render: (_, record) => record?.invest_id?.device?.full_name,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <TableTera
      columns={columns}
      loading={props?.loading || loadingDelete}
      {...props}
    />
  );
}

export default SalesExpensesTable;

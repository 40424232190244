import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import { ORDER_PERMISSION_KEY } from '_common/constants/permission';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  formatNumber,
  InformationCircleOutlined,
  notification,
  Tag,
  Tooltip,
} from 'tera-dls';
import OrderApi from '../../api';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { ORDER_URL } from '../../url';

function OrderTable(props: ITeraTableProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const { mutate, isLoading: loadingChangeStatus } = useMutation(
    (params: any) =>
      OrderApi.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => OrderApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleReject = (record) => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn đặt hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xác nhận từ chối đơn đặt hàng</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: record?.id,
          params: {
            status: 'reject',
            type_status: 'sumi',
          },
        };
        mutate(params);
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa đơn đặt hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn đặt hàng</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(ORDER_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => {
          if (record?.ticket_type === 'order') {
            navigate(`${ORDER_URL.detail.path}/${record?.id}`);
            return;
          }
          navigate(`${ORDER_URL.request_order_detail.path}/${record?.id}`);
        },
      });
    if (
      hasPage(ORDER_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.order_status === 'pending'
    )
      items.push({
        key: '2',
        label: 'Duyệt',
        onClick: () => navigate(`${ORDER_URL.approve.path}/${record?.id}`),
      });
    if (
      hasPage(ORDER_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.order_status === 'pending'
    )
      items.push({
        key: '3',
        label: 'Từ chối',
        onClick: () => handleReject(record),
      });
    if (
      hasPage(ORDER_PERMISSION_KEY.UPDATE) &&
      record?.order_status !== 'approved'
    )
      items.push({
        key: '4',
        label: 'Sửa',
        onClick: () => navigate(`${ORDER_URL.update.path}/${record?.id}`),
      });
    if (
      hasPage(ORDER_PERMISSION_KEY.DELETE) &&
      record?.order_status !== 'approved'
    )
      items.push({
        key: '5',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      width: 100,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_name',
      width: 150,
      render: (invest_name) => <p className="line-clamp-2">{invest_name}</p>,
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (type) => INVESTMENT_CATEGORY[type],
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_name',
      width: 200,
      render: (investor_name) => (
        <p className="line-clamp-2 break-word">{investor_name || 'Hệ thống'}</p>
      ),
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      width: 150,
      render: (device_name) => <p className="line-clamp-2">{device_name}</p>,
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'ticket_type',
      width: 100,
      render: (ticket_type) => TICKET_TYPE[ticket_type],
    },
    {
      title: 'Tổng SL đặt hàng',
      dataIndex: 'sum_quantity_request',
      width: 100,
      render: (sum_quantity_request) =>
        sum_quantity_request?.total_quantity_request &&
        formatNumber(sum_quantity_request?.total_quantity_request),
    },
    {
      title: 'Tổng SL duyệt',
      dataIndex: 'sum_quantity_approved',
      width: 100,
      render: (sum_quantity_approved, record) =>
        record?.order_status === 'approved' &&
        formatNumber(sum_quantity_approved?.total_quantity_approved),
    },
    {
      title: 'Tổng thành tiền',
      dataIndex: 'order_detail_total_price',
      width: 100,
      render: (order_detail_total_price) =>
        order_detail_total_price?.total_price &&
        formatCurrency(order_detail_total_price?.total_price),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) => created_at && formatDate(created_at, DATE_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'order_status',
      width: 150,
      render: (status, record) =>
        status && (
          <div className="flex gap-x-2.5 items-center">
            <Tag color={ORDER_STATUS_COLOR[status]}>{ORDER_STATUS[status]}</Tag>
            {record?.type_status === 'sumi' && (
              <Tooltip title={`SUMI ${ORDER_STATUS[status]}`} placement="top">
                <div>
                  <InformationCircleOutlined className="w-5 h-5" />
                </div>
              </Tooltip>
            )}
          </div>
        ),
    },
    {
      width: 60,
      fixed: 'right',
      dataIndex: 'action',
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <TableTera
      columns={columns}
      loading={props?.loading || loadingChangeStatus || loadingDelete}
      {...props}
    />
  );
}

export default OrderTable;

import HeaderViewList from '_common/component/HeaderViewList';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { BookmarkOutlined, Button, Col, Row } from 'tera-dls';
import { TIME_CONFIG } from './constants';
import { useForm } from 'react-hook-form';
function TimeConfig() {
  const form = useForm();
  const isDirty = form.formState.isDirty;

  const handleSubmitForm = (values) => console.log(values);

  const options = Object.entries(TIME_CONFIG).map(([value, label]) => ({
    label,
    value,
  }));

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Cấu hình thời gian"
        buttonAddRender={() => (
          <Button
            type="success"
            prefix={<BookmarkOutlined />}
            onClick={() => form.handleSubmit(handleSubmitForm)()}
            disabled={!isDirty}
          >
            Lưu
          </Button>
        )}
      >
        <FormTera form={form} className="tera-page-form">
          <div className="page-content-v2 !py-4">
            <Row className="grid-cols-2 gap-x-[30px]">
              <Col>
                <p className="font-medium text-blue-500 mb-4">
                  Thời gian duyệt đơn
                </p>
                <p className="font-light text-gray-500 italic mb-2.5">
                  Cài đặt khoảng thời gian hệ thống tự duyệt đơn kể từ lúc đơn
                  về hệ thống
                </p>
                <FormTeraItem
                  name="approve_config_order"
                  label="Đơn đặt hàng"
                  layout="inline"
                  labelClassName="w-1/2"
                >
                  <Select options={options} />
                </FormTeraItem>
                <FormTeraItem
                  name="approve_config_order_inspection"
                  label="Đơn kiểm hàng"
                  layout="inline"
                  labelClassName="w-1/2"
                >
                  <Select options={options} />
                </FormTeraItem>
                <FormTeraItem
                  name="approve_config_order_return"
                  label="Đơn trả hàng"
                  layout="inline"
                  labelClassName="w-1/2"
                >
                  <Select options={options} />
                </FormTeraItem>
                <FormTeraItem
                  name="approve_config_incurred_cost"
                  label="Chi phí phát sinh"
                  layout="inline"
                  labelClassName="w-1/2"
                >
                  <Select options={options} />
                </FormTeraItem>
              </Col>
              <Col>
                <p className="font-medium text-blue-500 mb-4">
                  Thời gian xác nhận đơn
                </p>
                <p className="font-light text-gray-500 italic mb-2.5">
                  Cài đặt khoảng thời gian hệ thống tự xác nhận đơn kể từ lúc
                  đơn về hệ thống
                </p>
                <FormTeraItem
                  name="confirm_config_order"
                  label="Đơn đặt hàng"
                  layout="inline"
                  labelClassName="w-1/2"
                >
                  <Select options={options} />
                </FormTeraItem>
                <FormTeraItem
                  name="confirm_config_order_return"
                  label="Đơn trả hàng"
                  layout="inline"
                  labelClassName="w-1/2"
                >
                  <Select options={options} />
                </FormTeraItem>
              </Col>
            </Row>
          </div>
        </FormTera>
      </HeaderViewList>
    </div>
  );
}

export default TimeConfig;

import NoData from '_common/component/NoData';
import { IconButton } from '_common/component/TableColumnCustom';
import { REGEX, SUMI_SHADOW } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  ItemType,
  Row,
  Spin,
  Tabs,
} from 'tera-dls';
import { PERMISSION_URL } from '../../url';

const headingClassName =
  'text-blue-500 font-medium text-base border-b py-4 px-2.5';

const dataMock = {
  investor: {
    name: 'Nhà đầu tư',
    controls: [
      {
        id: 1,
        title: 'Xem danh sách nhà đầu tư',
        key: 'ivt_investor_view_investor_list',
      },
      {
        id: 2,
        title: 'Bộ lọc và tìm kiếm nhà đầu tư',
        key: 'ivt_investor_search_investor',
      },
    ],
  },
  invest: {
    name: 'Đầu tư',
    controls: [
      {
        id: 3,
        title: 'Xem danh sách đầu tư',
        key: 'ivt_investor_view_invest_list',
      },
      {
        id: 4,
        title: 'Bộ lọc và tìm kiếm Đầu tư',
        key: 'ivt_investor_search_invest',
      },
    ],
  },
};

function PermissionForm() {
  const [listPermission, setListPermission] = useState<string[]>([]);
  const [activeKeyEpic, setActiveKeyEpic] = useState<string>(null);
  const [activeKeyModule, setActiveKeyModule] = useState<string>(null);

  const form = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmitForm = (values) => console.log(values);

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(PERMISSION_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách quyền
        </a>
      ),
    },
    {
      title: id ? 'Sửa cấu hình quyền' : 'Thêm cấu hình quyền',
    },
  ];

  const handleChangeCheck = (e) => {
    if (e.target.checked) {
      setListPermission((prev) => [...prev, e.target.value]);
      return;
    }
    setListPermission((prev) => {
      const largeSet = new Set(prev);
      largeSet.delete(e.target.value);
      return Array.from(largeSet);
    });
  };

  const handleChangeAllEpic = useCallback(
    (e) => {
      const listSetPermission = new Set(listPermission);
      dataMock[activeKeyEpic]?.controls.forEach((item) => {
        if (e.target.checked) {
          if (!listSetPermission.has(item.key)) {
            listSetPermission.add(item.key);
          }
          return;
        }
        if (listSetPermission.has(item.key)) listSetPermission.delete(item.key);
      });
      setListPermission(Array.from(listSetPermission));
    },
    [activeKeyEpic, dataMock, listPermission],
  );

  const handleChangeAllModule = useCallback(
    (e) => {
      const listSetPermission = new Set(listPermission);
      const listKey = Object.keys(dataMock).reduce(
        (total, current) => [...total, ...dataMock[current].controls],
        [],
      );
      listKey.forEach((item) => {
        if (e.target.checked) {
          if (!listSetPermission.has(item.key)) {
            listSetPermission.add(item.key);
          }
          return;
        }
        if (listSetPermission.has(item.key)) listSetPermission.delete(item.key);
      });
      setListPermission(Array.from(listSetPermission));
    },
    [dataMock, listPermission],
  );

  const isCheckAllModule = useMemo(() => {
    const listSetPermission = new Set(listPermission);
    const listKey = Object.keys(dataMock).reduce(
      (total, current) => [...total, ...dataMock[current].controls],
      [],
    );
    const allKeysExist = listKey.every((item) =>
      listSetPermission.has(item.key),
    );
    return allKeysExist;
  }, [listPermission, dataMock]);

  const isCheckAllEpic = useMemo(() => {
    const listSetPermission = new Set(listPermission);
    const allKeysExist = dataMock[activeKeyEpic]?.controls.every((item) =>
      listSetPermission.has(item.key),
    );
    return allKeysExist;
  }, [activeKeyEpic, listPermission, dataMock]);

  useEffect(() => {
    setActiveKeyEpic(Object.keys(dataMock)[0]);
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              // disabled={isError || (isLoading && !!id) || isLoadingMutate}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={false}>
        <div className="page-content-v2">
          {false ? (
            <NoData />
          ) : (
            <FormTera
              form={form}
              className={classNames('p-4 rounded bg-white', SUMI_SHADOW)}
            >
              <Row className="grid-cols-2">
                <FormTeraItem
                  label="Mã quyền"
                  name="code"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.CODE),
                        message: messageValidate.code,
                      },
                      maxLength: {
                        value: 100,
                        message: messageValidateLength.text,
                      },
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
                <FormTeraItem
                  label="Tên quyền"
                  name="name"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      maxLength: {
                        value: 100,
                        message: messageValidateLength.text,
                      },
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
                <Col className="col-span-2 rounded overflow-hidden border">
                  <Row className="grid-cols-4 gap-0">
                    <Col className={classNames(headingClassName, 'col-span-1')}>
                      Danh sách module
                    </Col>
                    <Col
                      className={classNames(
                        headingClassName,
                        'col-span-3 border-l',
                      )}
                    >
                      Danh sách màn hình và chức năng
                    </Col>
                    <Col className="col-span-1 bg-[#F3F4F6]">
                      <Tabs
                        activeClassName="bg-white"
                        className="sumi-system-permission__tab-container border-r-0"
                        itemClassName="sumi-system-permission__tab-item border-b"
                        tabPosition="left"
                        items={[
                          {
                            key: 'investor',
                            label: 'Nhà đầu tư',
                          },
                          {
                            key: 'sale',
                            label: 'Bán hàng',
                          },
                          {
                            key: 'hr',
                            label: 'Nhân sự',
                          },
                        ]}
                        activeKey={activeKeyModule}
                        onChange={(key) => setActiveKeyModule(key)}
                      />
                    </Col>
                    <Col className="col-span-3 py-2.5 px-4">
                      <Checkbox
                        onChange={handleChangeAllModule}
                        checked={isCheckAllModule}
                      >
                        Cho phép tất cả quyền
                      </Checkbox>
                      <Tabs
                        items={[
                          {
                            key: 'investor',
                            label: 'Nhà đầu tư',
                          },
                          {
                            key: 'invest',
                            label: 'Đầu tư',
                          },
                        ]}
                        activeKey={activeKeyEpic}
                        onChange={(key) => setActiveKeyEpic(key)}
                      />
                      <Checkbox
                        onChange={handleChangeAllEpic}
                        checked={isCheckAllEpic}
                      >
                        Tất cả
                      </Checkbox>
                      <Row className="pl-10 grid-cols-5 mt-2.5">
                        {dataMock[activeKeyEpic]?.controls?.map((item) => {
                          const newItemsSet = new Set(listPermission);
                          const checked = newItemsSet.has(item?.key);

                          return (
                            <Checkbox
                              value={item?.key}
                              checked={checked}
                              onChange={handleChangeCheck}
                            >
                              {item?.title}
                            </Checkbox>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormTera>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default PermissionForm;
